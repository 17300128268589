.SecretariatCorespondenceFormSended {
  position: relative;
  height: 100%;

  .icon {
    cursor: pointer;
  }
  .attachmentName {
    overflow: hidden;
    max-width: 25ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .deliveryAttachmentRow {
    padding-top: 1.5rem;
    height: 2.65rem;
    .BMXTextField {
      margin: 0;
      font-size: 0.9em;
    }

    .deliveryNoteAttachmentItem {
      font-size: 0.95rem;
      display: flex;
      align-items: center;
    }
  }

  > div {
    position: relative;
    height: 100%;

    > div {
      position: relative;
      height: 100%;
    }
  }

  [data-swipeable] {
    overflow: hidden;
  }

  .react-swipeable-view-container {
    will-change: auto !important;
  }

  .SecretariatCorespondenceStepOne {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .row {
      margin: 0.2rem 0;
    }
  }

  .Add_To_Table {
    position: absolute;
    bottom: -35px !important;
    right: -15px !important;
  }

  .MuiInputLabel-root,
  .MuiInput-root {
    font-size: 0.9rem;
    input {
      padding: 4px 4px 2px 4px !important;
    }
  }

  .MuiAutocomplete-root {
    margin-top: 0 !important;
    .MuiInputLabel-root {
      top: auto;
      margin-top: auto;
    }
    .MuiInputLabel-shrink {
      margin-top: auto;
    }
  }

  @media (width >= 1700) {
    .extraLarge {
      flex: 0 0 auto !important;
      width: 100% !important;
    }
  }
}
